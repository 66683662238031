import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";

import "./List.scss"

const getChunked = companies => {
  const chunked = []
  let index = 0
  while(index < companies.length) {
    chunked.push(companies.slice(index, index+4))
    index += 4
  }
  return chunked
}

const CompanyList = ({ list }) => {
  if(!list) return null
  const chunked = getChunked(list)
  return (
    <div className="company-list">
      {chunked.map((fours) => {
        const emptyBlocks = 4 - fours.length
        const unique = fours[0].node.frontmatter.skunk.name
        return (
          <div className="company-list-row" key={unique}>
            <div className="side-content" />
            {fours.map(company => {
              const data = company.node.frontmatter.skunk
              const { name, description, link } = data
              const external = !link || link.match(/^http/)
              return (
                <React.Fragment key={name}>
                  {external
                  ? <a
                      href={`${link}?utm_source=vaporware&utm_medium=website&utm_campaign=startup`}
                      className="content"
                      target="_blank"
                    >
                      <GatsbyImage
                        image={data.logo.childImageSharp.gatsbyImageData}
                        alt={name}
                        className="logo" />
                    </a>
                  : <Link 
                      to={link}
                      className="content"
                    >
                      <GatsbyImage
                        image={data.logo.childImageSharp.gatsbyImageData}
                        alt={name}
                        className="logo" />
                    </Link>
                  }
                </React.Fragment>
              );
            })}
            {[...Array(emptyBlocks).keys()].map((k, i) => (
              <div key={i} className="content empty" />
            ))}
            <div className="side-content" />
          </div>
        );
      })}
      <div className="company-list-row blank-row">
        <div className="side-content" />
          <div className="blank" />
          <div className="blank" />
          <div className="blank" />
          <div className="blank" />
        <div className="side-content" />
      </div>
    </div>
  );
}

CompanyList.defaultProps = {
  companies: []
}

CompanyList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
}

export default CompanyList
