import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "src/components/new/Layout"
import Header from "src/components/new/Header"
import CompaniesList from "src/components/companies/List"

import "./startups.scss"

const StartupsPage = ({data: {companies}}) => {
  return (
    <Layout
      meta={{
        title: "Startups we've partnered with",
        description: "Startups we've partnered with at Vaporware"
      }}
    >  
      <div className="startups-page">
        <Header>
          <h1>Startups we've partnered with</h1>
        </Header>
        <div className="startups">
          <CompaniesList list={companies.edges} />
        </div>
      </div>
    </Layout>
  )
}

StartupsPage.defaultProps = {
  data: {
    page: {
      companies: [],
    },
  },
}

StartupsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      companies: PropTypes.shape({edges: PropTypes.arrayOf(PropTypes.object)}),
    }),
  }),
}

export default StartupsPage

export const pageQuery = graphql`
  query {
    companies: allMarkdownRemark(
      filter: { frontmatter: { meta: {template: {eq: "company"}, indexed: {ne: false}}}},
      sort: {fields: frontmatter___meta___date, order: DESC}
    ) {
      edges {
        node {
          ...CompanyFragment
        }
      }
    }
  }
`
